import React from "react";
import theme from "theme";
import { Theme, Link, Image, Button, Section, Text, Box, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Contact - Dataleaks.us
			</title>
			<meta name={"description"} content={"Contact us"} />
			<meta property={"og:title"} content={"Contact - Dataleaks.us"} />
			<meta property={"og:description"} content={"Contact us"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://i.imgur.com/eGfhJiA.png"} type={"image/x-icon"} />
		</Helmet>
		<Section align-items="center" justify-content="center" padding="16px 0 16px 0" quarkly-title="Header">
			<Override
				slot="SectionContent"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				display="grid"
			/>
			<Link href="/" position="relative" transition="opacity 200ms ease" quarkly-title="Link">
				<Image
					src="https://uploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00:59:29.119Z"
					width="120px"
					z-index="3"
					srcSet="https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
				<Override
					slot="menu"
					lg-transform="translateY(0px) translateX(0px)"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-left={0}
					md-top={0}
					padding="0px 0 0px 0"
					justify-content="center"
					md-width="100%"
					md-height="100%"
					align-items="center"
				>
					<Override
						slot="item"
						text-transform="uppercase"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						transition="opacity .15s ease 0s"
						md-color="--dark"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						color="--dark"
						opacity=".5"
						font="--base"
						letter-spacing="0.5px"
						text-transform="initial"
						md-transition="opacity .15s ease 0s"
						hover-opacity="1"
						md-font="16px/24px sans-serif"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						md-opacity="1"
						md-cursor="default"
						opacity="1"
						color="--primary"
						cursor="default"
					/>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
				<Override
					slot="icon"
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
					icon={MdMenu}
				/>
				<Override
					slot="menu-open"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
				/>
				<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
			</Components.BurgerMenu>
			<Button
				white-space="nowrap"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				padding="8px 18px 8px 18px"
				background="--color-primary"
				z-index="5"
				md-display="none"
				border-radius="0px"
				hover-transform="translateY(-4px)"
				justify-self="end"
				font="--base"
				letter-spacing="0.5px"
			>
				Contact Us
			</Button>
		</Section>
		<Section quarkly-title="Hero" padding="25px 0 75px 0" lg-padding="25px 0 25px 0" justify-content="center">
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				align-items="center"
				justify-content="center"
				background="url(),radial-gradient(at center,--color-darkL2 23.3%,rgba(0,0,0,0) 82.4%),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-darkL2"
				md-min-height="360px"
				sm-min-height="180px"
				width="100%"
				min-height="480px"
				display="flex"
			>
				<Text
					align-items="center"
					justify-content="center"
					sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					text-align="center"
					font="--headline1"
					display="flex"
					color="--light"
					quarkly-title="Title"
					sm-text-align="center"
					md-font="--headline2"
					lg-font="--headline2"
					margin="0px 0px 0px 0px"
				>
					Donate / contact
				</Text>
			</Box>
		</Section>
		<Section
			justify-content="center"
			box-sizing="border-box"
			quarkly-title="Contacts"
			padding="50px 0px 50px 0px"
			lg-padding="25px 0px 25px 0px"
		>
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				display="grid"
				grid-gap="82px"
				grid-template-columns="7fr 3fr"
				md-grid-template-columns="1fr"
				md-grid-gap="40px"
				lg-grid-gap="36px"
				width="100%"
			>
				<Box
					border-style="solid"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					background="--color-lightD1"
					padding="48px 36px 54px 36px"
					border-width="1px"
				>
					<Text
						quarkly-title="Title"
						md-font="--headline3"
						font="--headline3"
						margin="0px 0px 15px 0px"
						display="inline-block"
					>
						<Strong>
							Support Our Mission:
						</Strong>
						<br />
						{"\n\n"}
					</Text>
					<Text
						color="--dark"
						md-font="--base"
						font="--base"
						margin="0px 0px 28px 0px"
						display="inline-block"
						quarkly-title="Description"
					>
						As a nonprofit, we rely on your generosity to continue our work. Consider donating to help us keep the digital community safe.
						<br />
						{"\n\n"}
					</Text>
				</Box>
				<Box>
					<Box margin="0px 0px 36px 0px" quarkly-title="Working Hours" xl-margin="0px 0px 24px 0px">
						<Text
							quarkly-title="Title"
							color="--dark"
							text-transform="uppercase"
							xl-margin="0px 0px 4px 0px"
							font="--base"
							letter-spacing="1px"
							margin="0px 0px 10px 0px"
						>
							Paypal:
						</Text>
						<Text
							quarkly-title="Description"
							color="--dark"
							font="--base"
							margin="0px 0px 0px 0px"
							display="inline-block"
							opacity="0.6"
						>
							paypal.me/err785{"\n\n"}
						</Text>
					</Box>
					<Box quarkly-title="Contact" xl-margin="0px 0px 24px 0px" margin="0px 0px 36px 0px">
						<Text
							color="--dark"
							text-transform="uppercase"
							xl-margin="0px 0px 4px 0px"
							font="--base"
							letter-spacing="1px"
							margin="0px 0px 10px 0px"
							quarkly-title="Title"
						>
							venmo:
						</Text>
						<Text
							color="--dark"
							quarkly-title="Email"
							font="--base"
							margin="0px 0px 0px 0px"
							display="inline-block"
							opacity="0.6"
						>
							@qixuann
						</Text>
					</Box>
					<Box margin="0px 0px 36px 0px" quarkly-title="Working Hours" xl-margin="0px 0px 24px 0px">
						<Text
							font="--base"
							letter-spacing="1px"
							margin="0px 0px 10px 0px"
							quarkly-title="Title"
							color="--dark"
							text-transform="uppercase"
							xl-margin="0px 0px 4px 0px"
						>
							email us:
						</Text>
						<Text
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							opacity="0.6"
							transition="opacity .15s ease 0s"
							target="_blank"
							display="block"
							text-decoration-line="initial"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
							hover-opacity=".5"
							quarkly-title="Phone"
							color="--dark"
							font="--base"
						>
							support@dataleak.us
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 40px 0" quarkly-title="Footer-12">
			<Box
				display="grid"
				lg-width="100%"
				flex-direction="row"
				lg-flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 0 0px"
				lg-padding="0 0px 50px 0px"
				margin="0px 0px 50px 0px"
				md-flex-wrap="wrap"
				md-padding="0 0px 0 0px"
				md-margin="0px 0px 40px 0px"
				grid-template-columns="repeat(3, 1fr)"
				lg-align-items="start"
				grid-gap="36px 34px"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					align-items="flex-start"
					lg-align-items="flex-start"
					justify-content="flex-start"
					display="grid"
					lg-flex-direction="column"
					flex-direction="column"
					flex-wrap="wrap"
					align-content="start"
					grid-gap="10px 0"
					lg-justify-content="start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans" md-margin="0px 0px 10px 0px">
						Menu
					</Text>
					<Link
						border-color="--color-primary"
						display="flex"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						margin="0px 0 0px 0"
						lg-border-width="0px 0px 0px 2px"
						href="/index"
						text-decoration-line="initial"
						color="--darkL1"
						hover-color="#6d32ec"
					>
						Home
					</Link>
					<Link
						margin="0px 0 0px 0"
						display="flex"
						href="/about"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						text-decoration-line="initial"
						color="--darkL1"
						hover-color="#6d32ec"
					>
						About
					</Link>
					<Link
						margin="0px 0 0px 0"
						hover-color="#6d32ec"
						href="/team"
						text-decoration-line="initial"
						color="--darkL1"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						display="flex"
					>
						Password-check
					</Link>
					<Link
						margin="0px 0 0px 0"
						hover-color="#6d32ec"
						href="https://article.dataleaks.us/"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						text-decoration-line="initial"
						color="--darkL1"
						display="flex"
					>
						Articles
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-padding="0px 0px 0px 0"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans">
						Contact
					</Text>
					<Text margin="0px 0px 20px 0px" font="--base" color="#5a5d64">
						support@dataleaks.us{"\n\n"}
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-padding="0px 0px 0px 0"
					md-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans">
						Dataleaks.us
					</Text>
					<Text margin="0px 0px 40px 0px" font="--base" color="#5a5d64">
						dataleaks.us is a non-profit organization that watches data breach events around the world, Helping people know and protect their properties.{"\n\n\n\n\n\n"}
					</Text>
				</Box>
			</Box>
			<Box
				display="block"
				padding="0 0px 0px 0px"
				border-color="--color-lightD2"
				md-padding="0 0px 0px 0px"
				md-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="#5a5d64"
					md-margin="0px 0px 25px 0px"
					sm-text-align="center"
					text-align="center"
					letter-spacing="1px"
				>
					© 2021-2023 Dataleaks.us  . All rights reserved.{"\n\n"}
				</Text>
			</Box>
		</Section>
	</Theme>;
});